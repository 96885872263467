<template>
  <v-container>
    <v-row>
      <v-col v-for="product in getProducts" :key="product.id" cols="12" sm="12">
        <ProductTemplate
          :product="product"
          :total_products_sold_type="'percentage'"
          :selectProductAndOpenModal="selectProductAndOpenModal"
          :copyToClipboard="copyToClipboard"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductTemplate from '@/components/Seller/ProductTemplate.vue'

export default {
  props: {
    products: Array,
  },
  components: {
    ProductTemplate,
  },
  methods: {
    async selectProductAndOpenModal(product, modal) {
      console.log('holas')
    },

    copyToClipboard(phrase) {
      const text = `Hola! Aquí podrás comprar tu entrada. Link ecommerce/producto-seleccionado. La misma es de acceso exclusivo y necesitaras ingresar una palabra clave para iniciar la compra. Tu palabra clave es: ${phrase}`
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)
      // Agrega una notificación o mensaje para indicar que el texto se ha copiado al portapapeles.
      this.$toast.success('Texto copiado al portapapeles')
    },
  },

  computed: {
    getProducts() {
      return this.products
    },
  },
}
</script>

<style scoped>
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50%; /* Ajusta el ancho máximo según tu preferencia */
}
</style>
