
import { Component, Prop, Vue } from 'vue-property-decorator'
import { isoDateToHour } from '@/helpers/date'

@Component({
  components: {},
})
export default class ProductTemplate extends Vue {
  @Prop() readonly product
  @Prop() readonly total_products_sold_type
  @Prop() selectProductAndOpenModal
  @Prop() copyToClipboard

  showSellers = false

  dateToHours(isoDate) {
    return isoDateToHour(isoDate)
  }

  goToEditProduct(productId, step) {
    window.location.href =
      process.env.VUE_APP_ADMIN_DOMAIN +
      '/products/edit/' +
      productId +
      (step ? '?step=' + step + '&' : '?') +
      'jwt=' +
      localStorage.getItem('access_token')
  }

  ocupate(totalTicket) {
    return totalTicket || 0
  }

  goToProductTickets(id) {
    this.$router.push({
      name: 'all-links',
      query: {
        productId: id,
      },
    })
  }

  async shareProduct(productUuid: string) {
    this.$router.push(`/generate-exlusive-ticket/${productUuid}`)
  }
}
